import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { AccessGuard } from './shared/guards/access.guard'
import { IsOnboardedGuard } from './shared/guards/is-onboarded.guard'
import { WizardLayoutComponent } from './layouts/components/wizard-layout/wizard-layout.component'
import { LayoutComponent } from './layouts/components/layout/layout.component'
import { ConnectProviderComponent } from './settings/connect-provider/connect-provider.component'
import { checkOrgReadyGuard } from './shared/guards/check-org-ready.guard'

const layoutRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [checkOrgReadyGuard(), AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'partner',
    loadChildren: () => import('./partner/partner.module').then(m => m.PartnerModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'charts',
    loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'billing',
    loadChildren: () => import('./billing/billing.module').then(m => m.BillingModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'teams',
    loadChildren: () => import('./teams/teams.module').then(m => m.TeamsModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'to-do',
    loadChildren: () => import('./to-do-tasks/to-do-tasks.module').then(m => m.ToDoTasksModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'collaborations',
    loadChildren: () => import('./collaborations/collaborations.module').then(m => m.CollaborationsModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'collab-report',
    loadChildren: () => import('./collab-report/collab-report.module').then(m => m.CollabReportModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  },
  {
    path: 'notifications',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AccessGuard, IsOnboardedGuard]
  }
]

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    children: layoutRoutes
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: '',
    component: WizardLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./shared/modules/layout-wizard/wizard-layout.module').then(m => m.WizardLayoutModule),
        canActivate: [AccessGuard, IsOnboardedGuard]
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'signup',
    redirectTo: 'auth/signup',
    pathMatch: 'full'
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full'
  },
  {
    path: 'logout',
    redirectTo: 'auth/logout',
    pathMatch: 'full'
  },
  {
    path: 'connect/:provider',
    component: ConnectProviderComponent
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  } // Wildcard route for a 404 page
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
